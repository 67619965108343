@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html {
    scroll-behavior: smooth !important;
}

* {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    color: whitesmoke !important;
}

.sizeTextNoWrap {
        width: 100px !important;
    }
.colorItems {
    color: #EED595 !important;
}

.colorBorder {
    border-top: 1px solid #E8C773 !important;
}

.colortext {
    color: #E8C773 !important;
}

a {
    text-decoration: none !important;
    font-size: 1.5em;
    width: min-content;
}

#split {
    position: relative;
}

nav div {
    background-color: #130F0C;
}

li article p {
    min-width: min-content !important;
    background-color: #130F0C;
}

.widthPrice {
    width: min-content !important;

}

.listIngredients {
    width: 75% !important;
}

.title {
    width: min-content !important;
    white-space: nowrap;
}

.list-group-item a {
    color: #130F0C !important;
}

.scale-on-hover {
    font-size: 20px;
    transition: transform 0.5s ease;
}

.scale-on-hover:hover {
    font-size: 25px;
    transform: scale(1.2);
}

#positionImage {
    left: 10% !important;
}

.fs40 {
    font-size: 40px !important;
}

.customLetterSpacing {
    letter-spacing: 10px;
}

.grayed {
    color: gray !important;
}

@media (min-width: 768px) {
    .sizeTextNoWrap {
        white-space: nowrap;
        width: min-content !important;
    }
}
main {
    scroll-behavior: auto !important;
    -webkit-font-smoothing: antialiased;
}