.galerieForme {
    width: 266px;
}
.galerie {
    height: 200px;
}
.backGroundColor {
    background-color: rgb(56, 54, 54);
    height: 200px;
}
.backGroundColor img{
    height: 250px;
}
.nowrap {
    min-width: 127px !important;
    white-space: normal !important;
}
@media (min-width: 576px) {
    
    .nowrap {
        white-space: nowrap !important;
    }
}