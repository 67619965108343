main {
    flex: 1 !important;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    background-color: #130F0C;
}
/* Styles généraux des barres de défilement */
::-webkit-scrollbar {
    width: 8px;
}

/* Style de la poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

/* Style au survol de la poignée de la barre de défilement */
::-webkit-scrollbar-thumb:hover {
background-color: #555;
}