#textNowarp {
    min-width: 163px;
    white-space: normal;
}

@media (min-width: 768px) {
    
    #textNowarp {
        white-space: nowrap;
    }
}
@media (min-width: 1200px) {
    
    #formeHoraire {
        width: min-content;
    }
    #formeHoraire a {
        width: min-content !important;
    }
}