header {
    height: 300px;
    min-height: 300px;
    background-image: url("../../assets/images/backgroundMenu.jpg");
    background-position-y: 60%;
    background-size: cover;
}
h1 {
    font-size: 5em !important;
    color: #e2e2e2 !important;
}
.navbar-toggler-icon {
    background-image: url("../../assets/images/menu.png") !important;
    width: 35px;
    height: 35px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    
    background-image: url('../../assets/images/menu.png') !important;
}

#burgerMenu {
    color: whitesmoke !important;
    background-image: url("../../assets/images/boisTexture.jpg");
    right: 3% !important;
    top: 5% !important;
    z-index: 100 !important;
}
#menuBox {
    top: 10% !important;
    right: 1% !important;
    z-index: 100 !important;
    width: 99%;

}
#menuBox ul li {
    background-image: url("../../assets/images/boisTexture.jpg");
    z-index: 1;
    color: whitesmoke;
    border-left: transparent;
    border-right: transparent;
    border-radius: 2px;
}
#menuBox ul li:last-of-type {
    border-bottom: transparent;
}
#menuBox ul li:first-of-type {
    border-top: transparent;
}
#menuBox ul li a{
    z-index: 100;
    color: whitesmoke !important;
    
    border-radius: 2px;
}